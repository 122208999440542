<template lang="pug">
.filters
  .row.align-items-center
    .col-xl-5.col-lg-12
      .row
        .col-lg-6.col-md-6.col-sm-12.p-l-10.no-right-padding
          p.filter-title {{ $t("inventory_management.shop_name") }}
          AppDropdown.shop-name(
            allow-empty
            close-on-select
            searchable
            :placeholder="$t('inventory_management.select_shop')"
            :loading="shopsLoading"
            :value="filters.shop"
            :items="shopsItems"
            order-direction="keep"
            @select="setShop"
            @remove="setShop({})"
          )
        .col-lg-6.col-md-6.col-sm-12.no-right-padding
          p.filter-title {{ $t("inventory_management.car_class") }}
          AppDropdown.car-class-name(
            v-if="isEmpty(filters.shop)"
            :placeholder="$t('inventory_management.select_car_classes')"
            :disabled="true"
            v-b-tooltip.hover
            :title="$t('inventory_management.select_shop_first')"
          )
          AppDropdown.car-class-name(
            v-else
            checkbox
            batch-select
            expanded
            multiple
            allow-empty
            close-on-select
            searchable
            :loading="carClassesLoading"
            value-key="id"
            title-key="name"
            :placeholder="$t('inventory_management.select_car_classes')"
            :value="filters.car_classes"
            :items="carClasses"
            order-direction="preorder"
            :is-item-disabled="isItemDisabled"
            @select="setCarClasses"
            @closed="$emit('closed:car-classes')"
            :max-select-count="maxSelectCountCarClass"
            :tips="$t('general_settings.car_class_count_tip')"
            :is-close-on-click-outside="isCloseDropdownOnClickOutside"
          )
    .col-xl-7.col-lg-12
      .row
        .col-lg-6.col-md-6.col-sm-12.no-right-padding
          .period-radio
            AppRadioButton.period(
              :value="!filters.monthPickerEnabled"
              @change="enableDatePicker"
            )
            .label
              span {{ $t("inventory_management.period") }}
          .datepicker-wrapper
            AppDatepicker.w-75(
              :disabled="filters.monthPickerEnabled"
              :value="selectedDates"
              :range="true"
              :disabled-date="disabledDate"
              :clearable="false"
              @change="setDate"
            )
            AppButton.today.w-25(
              :disabled="filters.monthPickerEnabled"
              name="today"
              title="inventory_management.today"
              @click="setToday"
            )
        .col-lg-6.col-md-6.col-sm-12
          .period-radio
            AppRadioButton.month(
              :value="filters.monthPickerEnabled"
              @change="enableMonthPicker"
            )
            .label
              span {{ $t("inventory_management.month") }}
          AppMonthPicker(
            :enabled="filters.monthPickerEnabled"
            :initial-date="filters.start_date"
            :start-date="minDate"
            :end-date="maxDate()"
            @change="setDate"
          )
</template>

<script>
  // misc
  import { isEmpty } from "lodash-es"
  import { add as dateAdd } from "date-fns"
  import { currentDateInTimeZone } from "@/helpers/common"
  import withMaxSelectCountCarClass from "@/mixins/withMaxSelectCountCarClass"
  import { startOfPreviousMonthInTimeZone } from "@/helpers/dates"
  import { mapGetters } from "vuex"

  export default {
    props: {
      filters: Object,
      shopsItems: Array,
      carClasses: Array,
      shopsLoading: {
        type: Boolean,
        default: true
      },
      carClassesLoading: {
        type: Boolean,
        default: true
      },
      isCloseDropdownOnClickOutside: {
        type: Boolean,
        default: true
      }
    },

    mixins: [withMaxSelectCountCarClass],

    components: {
      AppDatepicker: () => import("@/components/elements/AppDatepicker"),
      AppDropdown: () => import("@/components/elements/AppDropdown"),
      AppButton: () => import("@/components/elements/AppButton"),
      AppRadioButton: () => import("@/components/elements/AppRadioButton"),
      AppMonthPicker: () => import("@/components/elements/AppMonthPicker")
    },

    computed: {
      ...mapGetters(["maxDate"]),

      minDate() {
        return startOfPreviousMonthInTimeZone()
      },

      selectedDates() {
        const { start_date, end_date } = this.filters
        return [start_date, end_date]
      }
    },

    methods: {
      isEmpty,

      isItemDisabled({ selectable }) {
        return !selectable
      },

      disabledDate(date) {
        return date < this.minDate || date > this.maxDate()
      },

      newSelectedDatesObject(start_date, end_date) {
        return { start_date, end_date }
      },

      enableDatePicker() {
        this.$emit("change:month-picker-enabled", false)
      },

      enableMonthPicker() {
        this.$emit("change:month-picker-enabled", true)
      },

      setShop(shop) {
        this.$emit("change-shop", shop.id)
        this.applyFilters({ shop, car_classes: [] })
      },

      setCarClasses(car_classes) {
        this.applyFilters({ car_classes }, false)
      },

      setDate(datesArray) {
        const [startDate, endDate] = datesArray
        const { maxDate } = this
        const dates = this.newSelectedDatesObject(startDate, endDate > maxDate ? maxDate : endDate)
        this.applyFilters(dates)
      },

      setToday() {
        const today = currentDateInTimeZone()
        const dates = this.newSelectedDatesObject(today, dateAdd(today, { months: 1 }))

        this.applyFilters(dates)
      },

      applyFilters(newFilters, fetch = true) {
        this.$emit("change-filters", { ...this.filters, ...newFilters }, fetch)
      }
    }
  }
</script>

<style lang="sass" scoped>
  @import "@/assets/styles/variables.sass"
  @import "@/assets/styles/mixins/common.sass"

  .filters
    +filter-title

    .datepicker-wrapper
      display: flex
      padding: 6px

    .app-select
      margin: 0

    .period-radio
      margin-left: 5px
      display: flex
      justify-content: left

      .app-radio-button
        margin-top: 2px

        ::v-deep
          .big-circle
            border: 1px solid $default-gray
            width: 15px
            height: 15px

          .small-circle
            width: 9px
            height: 9px

      .label
        +filter-title-styles


    .today
      color: $default-purple
      border: 1px solid $default-purple
      height: 38px
      padding: 5px 10px

    .datepicker-wrapper
      display: flex
      padding: 6px

      .mx-datepicker-range
        width: 100%
</style>
